import { DownloadOutlined } from "@ant-design/icons";
import { useTheme } from "@emotion/react";
import { Button, Tooltip } from "antd";
import { DrawerTypes } from "components/Drawer/drawerTypes";
import DownloadReportDrawer from "components/Drawer/drawers/DownloadReportDrawer";
import useDrawer from "components/Drawer/useDrawer";
import { Flex } from "components/Flex";
import SelectDropdownIndicator from "components/SelectDropdownIndicator";
import { Text } from "components/Text";
import InfoIcon from "components/icons/InfoIcon";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import useMapData from "hooks/useMapData";
import { useUserData } from "hooks/useUser";
import { ResponsiveContext } from "lib/context";
import { getRelativePathname } from "lib/getRelativePathname";
import { hasAccessToPremiumArea } from "lib/helpers/userHelpers";
import scoreConfigs from "lib/scoreBoard/scoreConfigs";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";
import CrownImage from "public/crown.svg";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { usePrevious } from "react-use";
import { Geo } from "types/MapContext";
import { ScoreDatapoints } from "types/cube";
import { ReportDownloadSuccess } from "./ReportDownloadSuccess";
import Spinner from "components/Spinner";

const ScoreGauge = dynamic(() => import("./ScoreGauge"), { ssr: false });

const ScoreCard = ({ geoCode, geo, stateCode, GEOID, name }) => {
  const theme = useTheme();
  const user = useUserData();
  const router = useRouter();
  const { showDrawer } = useDrawer();
  const { currentData, isCurrentLoading } = useMapData();
  const prevData = usePrevious(currentData);

  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  const [selectedTab, setSelectedTab] = useState(scoreConfigs[0]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const userCanAccess =
    user.canAccessPremium ||
    hasAccessToPremiumArea(
      user,
      { GEOID, state_code: stateCode, geo_code: geoCode },
      false,
      geo,
    );

  useEffect(() => {
    if (!!user.id && router.query && router.query.download) {
      showDrawer(DrawerTypes.DOWNLOAD_REPORT);
      router.replace(getRelativePathname(router), undefined, { shallow: true });
    }
  }, [user, router.query]);

  const scoreData = useMemo(() => {
    const infoData = (currentData || prevData).find(
      (cd) =>
        +cd.geo_code === +geoCode &&
        (geo !== Geo.COUNTY || cd.state_code == stateCode),
    );

    if (!infoData) return {};

    const data = {
      [selectedTab.code]: infoData[selectedTab.code],
    };

    selectedTab.extraData?.forEach((extra) => {
      data[extra.code] = infoData[extra.code];
    });

    return data;
  }, [currentData, selectedTab]);
  const isScorecardTabDisabled = (tab) => {
    const isDisabled =
      tab.premium &&
      tab.code !== ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE &&
      !userCanAccess;
    return isDisabled;
  };
  const renderTabs = (
    <Flex
      justify="space-around"
      css={{
        width: "98%",
        padding: 4,
        background: "rgba(118, 118, 128, 0.12)",
        borderRadius: theme.radii.larger,
        alignSelf: "center",
      }}
    >
      {scoreConfigs.map((tab) => {
        if (!tab.geo.includes(geo)) return null;
        return (
          <Tooltip
            trigger={tab.isComingSoon ? ["hover"] : []}
            key={tab.label}
            title={"Coming Soon"}
            placement="bottom"
            overlayClassName="score-tab-tooltip"
            overlayInnerStyle={{
              background: theme.colors.primary,
              borderRadius: theme.radii.small,
            }}
          >
            <Flex
              key={tab.label}
              justify="center"
              align="center"
              gap={theme.gap[1]}
              css={{
                padding: 6,
                cursor: "pointer",
                fontSize: theme.fontSizes.small,
                fontWeight: theme.fontWeights.bold,
                borderRadius: theme.radii.larger,
                background:
                  tab.label === selectedTab.label
                    ? theme.colors.white
                    : "transparent",
                boxShadow:
                  tab.label === selectedTab.label
                    ? "0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12)"
                    : undefined,
              }}
              onClick={() => {
                if (tab.isComingSoon) {
                  return;
                }
                setSelectedTab(tab);
              }}
            >
              {tab.label}
            </Flex>
          </Tooltip>
        );
      })}
    </Flex>
  );

  const header = (
    <Flex
      align="center"
      justify="space-between"
      css={{
        padding: 12,
        width: "100%",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
      }}
    >
      <Flex
        align={isTabletOrMobile ? "flex-start" : "flex-end"}
        direction={isTabletOrMobile ? "column" : "row"}
      >
        <span
          css={{
            fontSize: theme.fontSizes.heading2,
            fontWeight: theme.fontWeights.bold,
            lineHeight: "26px",
          }}
        >
          Score
        </span>
        <span css={{ fontSize: theme.fontSizes.default, lineHeight: "18px" }}>
          ({selectedTab.label})
        </span>
      </Flex>

      <Button
        type="primary"
        css={{
          background: theme.colors.black,
          borderColor: theme.colors.black,
        }}
        icon={<DownloadOutlined />}
        onClick={() => {
          if (!user?.uid) {
            router.push("/?type=signup&download=true");
            return;
          } else {
            showDrawer(DrawerTypes.DOWNLOAD_REPORT);
          }
        }}
      >
        Report
      </Button>
    </Flex>
  );

  return (
    <>
      <Flex
        flex={1}
        direction="column"
        align="center"
        css={{
          width: "100%",
          border: `1px solid rgba(0,0,0,0.2)`,
          backdropFilter: "blur(25px)",
        }}
      >
        {header}
        {isTabletOrMobile ? (
          <Select
            value={{
              value: selectedTab.code,
              label: selectedTab.label,
              premium: selectedTab.premium,
              isNew: selectedTab.isNew,
              isComingSoon: selectedTab.isComingSoon,
            }}
            options={scoreConfigs.map((sc) => {
              return {
                value: sc.code,
                label: sc.label,
                premium: sc.premium,
                isNew: sc.isNew,
                isComingSoon: sc.isComingSoon,
              };
            })}
            components={{
              DropdownIndicator: SelectDropdownIndicator,
            }}
            onChange={(option) => {
              if (option.isComingSoon) return;
              setSelectedTab(
                scoreConfigs.find((c) => c.code === option.value)!,
              );
            }}
            formatOptionLabel={(option: any) => {
              return (
                <Flex justify="space-between" align="center">
                  <Flex align="center" gap={4}>
                    <Text
                      css={{
                        color: option.isComingSoon
                          ? theme.colors.gray100
                          : undefined,
                      }}
                    >
                      {option.label}
                    </Text>
                    {option.isNew && (
                      <div
                        css={{
                          fontSize: theme.fontSizes.small,
                          color: theme.colors.primary,
                        }}
                      >
                        New
                      </div>
                    )}
                  </Flex>
                </Flex>
              );
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: theme.radii.small,
                borderWidth: "1px",
                borderColor: theme.colors.inputBorder,
                fontSize: theme.fontSizes.default,
                width: 330,
                maxWidth: "90%",
                margin: "auto",
                boxShadow: theme.shadow,
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                padding: "5px 12px",
                fontSize: "14.5px",
                paddingLeft: "16px",
                "&:hover": {
                  fontSize: "14.5px",
                },
              }),
              menu: (baseStyles) => {
                return {
                  ...baseStyles,
                  zIndex: 1000,
                };
              },
            }}
          />
        ) : (
          renderTabs
        )}

        {scoreData[selectedTab.code] ? (
          <ScoreGauge
            data={
              scoreData[selectedTab.code]
                ? scoreData[selectedTab.code] / 100
                : 0
            }
            scoreCode={selectedTab.code}
            theme={theme}
            tab={selectedTab}
          />
        ) : (
          <></>
        )}

        <Flex
          direction="column"
          css={{
            position: "relative",
            width: "100%",
            paddingBottom: 25,
          }}
        >
          {scoreData[selectedTab.code] && (
            <Flex
              direction="column"
              gap={theme.gap[8]}
              css={{
                width: "90%",
                alignSelf: "center",
              }}
            >
              {selectedTab?.extraData?.map((item) => {
                return (
                  <Flex
                    key={item.label}
                    direction="column"
                    css={{ width: "100%" }}
                  >
                    <div
                      css={{
                        display: "flex",
                        alignItems: "center",
                        color:
                            Math.floor(scoreData[item.code] || 0) <= 0 ?
                                theme.colors.taupeGray :
                                theme.colors.black,
                        gap: 7,
                        fontSize: theme.fontSizes.medium,
                        fontWeight: theme.fontWeights.bold,
                      }}
                    >
                      {item.label}
                      <Tooltip title={item.summary}>
                        <Flex>
                          <InfoIcon />
                        </Flex>
                      </Tooltip>
                    </div>
                    <div
                      css={{
                        height: 7,
                        width: "100%",
                        background: userCanAccess
                          ? theme.colors.lightBg
                          : theme.colors.black,
                        borderRadius: theme.radii.larger,
                        position: "relative",
                      }}
                    >
                      {userCanAccess && (
                        <>
                          <div
                            css={{
                              backgroundColor:
                                  Math.floor(scoreData[item.code] || 0) <= 0 ?
                                      theme.colors.taupeGray :
                                      theme.colors.black,
                              borderRadius: theme.radii.larger,
                              height: 7,
                              width: `${Math.abs(scoreData[item.code])}%`,
                            }}
                          />
                          <Flex
                            justify="center"
                            align="center"
                            css={{
                              position: "absolute",
                              width: 35,
                              height: 35,
                              backgroundColor:
                                  Math.floor(scoreData[item.code] || 0) <= 0 ?
                                      theme.colors.taupeGray :
                                      theme.colors.black,
                              borderRadius: theme.radii.full,
                              border: "3px solid white",
                              top: -14,
                              left: `calc(${Math.abs(
                                scoreData[item.code],
                              )}% - 17.5px)`,

                              color: theme.colors.white,
                              fontSize: theme.fontSizes.medium,
                              fontWeight: theme.fontWeights.bold,
                            }}
                          >
                            {Math.floor(scoreData[item.code] || 0)}
                          </Flex>
                        </>
                      )}
                    </div>
                  </Flex>
                );
              })}
            </Flex>
          )}
          {!scoreData[selectedTab.code] && !isCurrentLoading ? (
            <div css={{ margin: "20px auto" }}>
              <Text large align="center">
                Data Unavailable at the Moment
              </Text>
            </div>
          ) : isCurrentLoading ? (
            <div css={{ margin: "20px auto" }}>
              <Text large align="center">
                <Spinner />
              </Text>
            </div>
          ) : (
            <></>
          )}
          {!userCanAccess &&
            scoreData[selectedTab.code] &&
            !isCurrentLoading && (
              <>
                <div
                  css={{
                    position: "absolute",
                    width: "100%",
                    height: "calc(100% + 10px)",
                    background: `rgba(255, 255, 255, 0.33)`,
                    backdropFilter: "blur(11.949999809265137px)",
                    top: -10,
                    left: 0,
                  }}
                />

                <Flex
                  direction="column"
                  align="center"
                  css={{
                    position: "absolute",
                    top: "50%",
                    transform: "translate(0, -50%)",
                    right: 30,
                    maxWidth: 250,
                    padding: "20px 16px",
                    background: theme.colors.white,
                    borderRadius: theme.radii.small,
                    backdropFilter: "blur(11.949999809265137px)",
                    boxShadow: `0px 0px 6px 0px rgba(0,0,0,0.1)`,
                    WebkitBoxShadow: `0px 0px 6px 0px rgba(0,0,0,0.1)`,
                    MozBoxShadow: `0px 0px 6px 0px rgba(0,0,0,0.1)`,
                  }}
                >
                  <Image src={CrownImage} alt="Premium Crown" />
                  <Text
                    css={{
                      fontSize: theme.fontSizes.heading2,
                      fontWeight: theme.fontWeights.heading,
                      textAlign: "center",
                    }}
                  >
                    Access{" "}
                    {selectedTab.code ===
                    ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE
                      ? "Housing Market Forecast"
                      : selectedTab.label}{" "}
                    for {name}
                  </Text>
                  <Text
                    css={{
                      fontSize: theme.fontSizes.small,
                      textAlign: "center",
                    }}
                  >
                    Upgrade to view full Market Scores
                  </Text>
                  <Button
                    css={{
                      background: theme.colors.primary,
                      color: theme.colors.white,
                      width: "80%",
                    }}
                    onClick={() => {
                      setShowPlanModal(true);
                      setPlanPopupTrigger("Scorecard Unlock");
                    }}
                  >
                    Unlock
                  </Button>
                </Flex>
              </>
            )}
        </Flex>
      </Flex>

      <DownloadReportDrawer
        geo={geo}
        geoCode={geoCode}
        stateCode={stateCode}
        scoreConfig={selectedTab}
      />

      <ReportDownloadSuccess
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
      />
    </>
  );
};

export default ScoreCard;
