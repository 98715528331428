import { zipCountyMap } from "lib/constants";
import { countyList } from "lib/options/countyList";
import { countyMetroMap } from "lib/options/countyMetroMap";
import { countyZipMap } from "lib/options/countyZipMap";
import { metroMap } from "lib/options/metroMap";
import { stateList } from "lib/options/stateList";
import { zipMetro } from "lib/zipMetro";
import { Geo } from "types/MapContext";
import { FeatureState } from "types/Mapbox";

export const getCountyName = (zipCode: string) => {
  const countName = countyList.find(
    (county) => Number(county.value) === Number(zipCountyMap[zipCode]),
  )?.label;
  if (countName) {
    return countName;
  }
  return Object.keys(countyZipMap).find((key) =>
    countyZipMap[key].includes(Number(zipCode)),
  );
};

export const getMetroName = (
  ctx: FeatureState,
  geoType: Extract<Geo, Geo.COUNTY | Geo.ZIP>,
  options?: { short?: boolean; solo?: true },
): string => {
  let metro;

  if (geoType === Geo.ZIP) {
    metro = metroMap.find(
      (metro) => Number(metro["CBSA Code"]) === Number(zipMetro[ctx.GEOID]),
    );
  } else {
    const mapping = countyMetroMap.find(
      (m) =>
        m.fipscountycode === parseInt(ctx.geo_code) &&
        m.fipsstatecode === parseInt(ctx.state_code),
    );

    if (!mapping) return "";
    metro = metroMap.find((m) => m["CBSA Code"] === mapping.cbsacode);
  }

  return (
    (options?.short
      ? metro?.CBSA
      : options?.solo
      ? metro?.["CBSA Solo"]
      : metro?.["CBSA Full Title"]) || ""
  );
};

export const getStateName = (
  ctx: FeatureState,
  options?: { short?: boolean },
) => {
  const state = stateList.find((s) => +s.value === +ctx.state);
  return (options?.short ? state?.abbreviation : state?.label) || "";
};
