import { useTheme } from "@emotion/react";
import { Switch } from "antd";
import { Flex } from "components/Flex";
import { CustomTooltip } from "components/Tooltip";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { useUserData } from "hooks/useUser";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useContext } from "react";

interface Props {
  showYearly: boolean;
  setShowYearly: Dispatch<SetStateAction<boolean>>;
}

const GraphSwitch = ({ showYearly, setShowYearly }: Props) => {
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const user = useUserData();
  const router = useRouter();
  const theme = useTheme();

  return (
    <CustomTooltip
      title="To use slider, upgrade to premium plan"
      overlayInnerStyle={{ width: 200, borderRadius: theme.radii.medium }}
      open={user.isPremiumOrBasic ? false : undefined}
    >
      <Flex
        justify="space-between"
        align="center"
        css={(theme) => ({
          height: 37,
          position: "absolute",
          right: router.asPath.startsWith("/graph") ? 120 : 160,
          top: 18,
          borderRadius: theme.radii.small,
          background: theme.colors.lightBg,
          display: "flex",
          width: user.isPremiumOrBasic ? 190 : 220,
          fontSize: theme.fontSizes.default,
          overflow: "hidden",
        })}
        onClick={() => {
          if (!user.isPremiumOrBasic) {
            setPlanPopupTrigger("Switch Graph To Monthly");
            setShowPlanModal(true);
          }
        }}
      >
        <Flex
          justify="center"
          align="center"
          flex="1"
          css={{
            fontWeight: showYearly
              ? theme.fontWeights.bold
              : theme.fontWeights.body,
          }}
        >
          Yearly
        </Flex>
        <Flex justify="center" align="center" flex="1">
          <Switch
            checked={!showYearly}
            onChange={() =>
              user.isPremiumOrBasic && setShowYearly((prev) => !prev)
            }
          />
        </Flex>
        <Flex
          justify="center"
          align="center"
          gap={4}
          flex="1.2"
          css={{
            fontWeight: showYearly
              ? theme.fontWeights.body
              : theme.fontWeights.bold,
          }}
        >
          Monthly
        </Flex>
      </Flex>
    </CustomTooltip>
  );
};

export default GraphSwitch;
